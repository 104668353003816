@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply m-0 font-sans antialiased bg-[#0f1b21] text-white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

@layer utilities {
  .font-code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
}